// Store
import { dynamicFormStore } from "../stores/store";
import { mapStores, mapState } from 'pinia'
import { email, required, minLength, maxLength } from "@vuelidate/validators";

export function zipCodeValidation(value) {
  let validNamePattern = new RegExp("^\[0-9]{5}?$");
  if (validNamePattern.test(value)) {
      return true;
  }
  return false
}

export default {
  computed: {
    ...mapStores(dynamicFormStore),
    ...mapState(dynamicFormStore, ['isProgressive', 'currentInputs', 'inputFields']),
    validatableFields() {
      return this.isProgressive ? this.currentInputs : this.inputFields
    },
    customValidations() {
      if (!this.validatableFields) return {};
      
      let validations = {}
      let formState = {}
      
      
      this.validatableFields.forEach(field => {
        validations[field.slug] = attachValidations(field)
        formState[field.slug] = null
      });
  
      return { answers: validations }
    }
  },
  validations() {
    return this.customValidations
  }
}

export function attachValidations(field) {
  let validation = {}

  if ([
    'email', 
    'given_name', 
    'last_name', 
    'phone', 
    'time_zone', 
    'zip_code',
    'confirm_age',
    'privacy_policy',
    'terms_and_conditions_accepted',
  ].includes(field.slug) ) {
    validation = {...validation, required }
  }

  if ([
    'zip_code',
  ].includes(field.slug) ) {
      validation = {...validation, ...{
        zipCodeValidation
      } 
    }
  }

  if (field.required) {
      validation = {...validation, required }
  }

  if ((field.validations || []).includes('email')) {
      validation = {...validation, email }
  }

  if (field.data_type_enum === 15) {
        validation = {
            ...validation,
            ... {
                number: {
                    required,
                    minLength: minLength(9)
                }
            }
        }
    }

  // if ((field.validations || []).includes('max:')) {
  //     let lengthMax = field.validations.find(x => x.includes('max:')).split(':')
  //     validation = {...validation, ...maxLength(lengthMax[lengthMax.length - 1]) }
  // }

  // if ((field.validations || []).includes('min:')) {
  //     let lengthMin = field.validations.find(x => x.includes('min:')).split(':')
  //     validation = {...validation, ...minLength(lengthMin[lengthMin.length - 1]) }

  // }

  return validation
}