<template>
  <div v-auto-animate>
    <Header />

    <component ref="formLayout" :cancelApiCall="cancelApiCall" :is="component" @resetVErrors="resetVErrors"/>

    <Footer @submit="submit" @onCancel="onCancel" @deleteCaptchaError="errors.captcha = {}" :hasErrors="errors.captcha"/>
  </div>
</template>
<script>
// Components
import Header from './components/Header&Footer/Header.vue';
import Footer from './components/Header&Footer/Footer.vue';
import Loading from './components/Layouts/Loading.vue';
import axios from 'axios';
// Packages
import { defineAsyncComponent } from "vue";
import { computed } from 'vue'
import Validations from './mixins/validations.js'
import useVuelidate from "@vuelidate/core";


// Store
import { dynamicFormStore } from "./stores/store";
import { mapStores, mapState, mapActions } from 'pinia'
export default {
  name: 'DynamicForm',
  mixins: [Validations],
  components: { Header, Footer },

  props:{
    data: Object,
    readonly: Boolean,
    cancelApiCall: { type: Boolean, default: false }
  },

  setup() {
    return { v$: useVuelidate() };
  },

  data: () => ({
    errors: [{captcha: {}}]
  }),

  beforeMount() {
    this.setFormData(this.data)
  },

  provide() {
    // use function syntax so that we can access `this`
    return {
      errors: computed(() => this.v$?.answers),
      submit: computed(() => this.submit),
      onSubmit: computed(() => this.onSubmit),
      isReadonly: computed(() => this.readonly)
    }
  },

  computed: {
    ...mapStores(dynamicFormStore),
    ...mapState(dynamicFormStore, ['answers', 'status', 'formType']),
    component() {
      return defineAsyncComponent({
        loader: () =>
            import (`./components/Layouts/${this.formType + 'Form'}.vue`),
        loadingComponent: Loading /* shows while loading */ ,
        delay: 0 /* delay in ms before showing loading component */ ,
        timeout: 3000 /* timeout after this many ms */ ,
      });
    },
  },

  methods: {
    ...mapActions(dynamicFormStore, ['setFormData']),
    submit(catpcha) {
      if (this.status.submit) return;
      this.v$.$validate().then(value => {
        if (value) {
          axios.post('/api/validate-recaptcha', {
            data: { recaptchaValue: catpcha }
          }).then(() => {
            this.$refs.formLayout.submit()
          }).catch((e) => {
              this.errors.captcha = {errorMessage: 'Captcha requerido'}
          })
        }
      })
    },
    resetVErrors() {
      this.v$.$reset();
    },

    onSubmit(answers, response) {
      this.$emit('onSubmit', answers, response)
    },

    onCancel() {
      this.$emit('onCancel')
    }
  }
}
</script>