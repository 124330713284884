<template>
  <div v-if="!isReadonly" class="w-100">
    <div class=" w-100"
      :class="{'justify-content-end': !cancelField, 'justify-content-between': cancelField}">
      <div class="d-flex justify-content-center mt-3">
        <div id="recaptcha-main" class="recaptcha" data-callback="myRecaptchaMethod" data-sitekey="6LeaZRYnAAAAAPbt6zfCuxG7bgTI62xERjvXWmQz"></div>
      </div>
      <div class="d-flex justify-content-center">
        <p class="error_message text-start main-p" v-if="hasErrors && hasErrors.errorMessage != ''"> {{hasErrors?.errorMessage}} </p>
      </div>
      <button v-if="cancelField"
              class="btn btn-secondary"
              @click.prevent="onCancel"
              :disabled="submitStatus"> {{ cancelField?.help }} </button>

      <button type="submit" @click.prevent="submit" :disabled="submitStatus" :class="submitField?.extra_parameters?.styles || 'main-btn'">
        <span v-if="submitStatus" class="spinner-border text-light spinner-border-sm mx-1" role="status"></span>
        {{ submitField?.help }}
      </button>
    </div>

    <div class="d-flex justify-content-end w-100" v-if="submitField?.extra_parameters?.help_text || submitField?.help">
      <span class=""> {{ submitField.extra_parameters.help_text }} </span>
    </div>
  </div>
</template>


<script>
// Store
import { dynamicFormStore } from "../../stores/store";
import { mapStores, mapState } from 'pinia'

export default {
  inject: ['isReadonly'],
  props: {
    hasErrors: {
      type: Object
    },
  },
  computed: {
      ...mapStores(dynamicFormStore),
      ...mapState(dynamicFormStore, ['submitField', 'submitStatus', 'cancelField', 'labelFields', 'fields'])
  },
  mounted(){
    // Assign data-callback to a method
    window.myRecaptchaMethod = this.myRecaptchaMethod;
  },
  methods: {
    myRecaptchaMethod: function(response) {
      this.$emit('deleteCaptchaError')
    },
    submit() {
      grecaptcha.enterprise.ready(async () => {
        const token = await grecaptcha.enterprise.execute('6LdZLg0qAAAAAPjngXpeBuqT_36vyPsB1OMxSlbl', {action: 'REGISTER'});
        
        this.$emit('submit', token)
      });      
    },

    onCancel() {
      this.$emit('onCancel')
    },
  },
}
</script>

<style>
.error_message {
  color: red;
}
@media screen and (max-width:475px) {
  .recaptcha {
    transform: scale(.82);
  }
}
</style>